<script setup lang="ts">
import Spacer from '~/components/common/Spacer.vue';
import Logo from '~/components/common/Logo.vue';
import AccountActions from '~/components/layout/external/shared/AccountActions.vue';
import HamburgerButton from '~/components/layout/external/mobile/HamburgerButton.vue';
import InvestmentsOffer from '~/components/layout/external/mobile/InvestmentsOffer.vue';
import HowItWorks from '~/components/layout/external/mobile/HowItWorks.vue';
import AboutUs from '~/components/layout/external/mobile/AboutUs.vue';
import EducationAndSupport from '~/components/layout/external/mobile/EducationAndSupport.vue';

const opened = ref(false);

function onOpenChange(isOpened: boolean) {
  opened.value = isOpened;

  const chat = document.getElementById('smartsupp-widget-container');

  if (opened.value) {
    document.body.style.overflow = 'hidden';

    if (chat) {
      chat.style.display = 'none';
    }
  } else {
    document.body.style.overflow = '';

    if (chat) {
      chat.style.display = 'block';
    }
  }
}

function onClose() {
  onOpenChange(false);
}

onUnmounted(() => {
  onOpenChange(false);
});
</script>

<template>
  <div class="container container--main">
    <div class="logo">
      <NuxtLink to="/">
        <Logo theme="light" :alt="$t('layout.external.navigation.logo-alt')" />
      </NuxtLink>
    </div>

    <Spacer />

    <AccountActions class="actions--tablet" />

    <HamburgerButton :opened="opened" @click="onOpenChange" />

    <div
      class="menu"
      :class="{
        'menu--opened': opened,
      }"
    >
      <div class="container">
        <InvestmentsOffer @close="onClose" />

        <HowItWorks @close="onClose" />

        <AboutUs @close="onClose" />

        <EducationAndSupport @close="onClose" />

        <AccountActions class="actions--mobile" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo:not(img) {
  display: inline-flex;
  align-items: center;

  width: 7.5rem;

  a {
    display: inherit;
  }
}

.container--main {
  position: relative;

  display: flex;
  align-items: center;

  padding-block: 0.625rem;

  @media only screen and (min-width: 1200px) {
    display: none;
  }

  &:has(.menu--opened) {
    margin-right: var(--scrollbar-width);
  }
}

.actions--tablet {
  display: none;

  @media only screen and (min-width: 576px) and (max-width: 1199.98px) {
    display: flex;
  }
}

.actions--mobile {
  display: flex;
  flex-direction: column-reverse;

  @media only screen and (min-width: 576px) {
    display: none;
  }

  :deep(.button) {
    justify-content: center;

    margin: 0;

    width: 100%;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }
}

.menu {
  position: fixed;
  top: var(--menu-clearfix-height);
  left: 0;
  right: 0;
  bottom: 0;

  display: none;

  padding-top: 18px;

  height: calc(100vh - var(--menu-clearfix-height));

  background-color: var(--gray-bright);

  overflow: auto;

  &--opened {
    display: block;
  }
}
</style>
