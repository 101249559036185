<script setup lang="ts">
const localePath = useLocalePath();
const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);
</script>

<template>
  <div class="actions">
    <template v-if="isLoggedIn">
      <Button as="NuxtLink" :to="localePath('dashboard')">{{
        $t('layout.external.navigation.account-actions.go-to-dashboard')
      }}</Button>
    </template>

    <template v-else>
      <Button :to="localePath('login')" as="NuxtLink" class="login" variant="text" theme="light">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            d="M4 20.9197C4.72 18.9997 6.52 17.6797 8.68 17.6797H13"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
          <path
            d="M22 20.9197C21.28 18.9997 19.48 17.6797 17.32 17.6797H13"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
          <path
            d="M18.2786 12.2799C18.2786 13.5999 17.7986 14.7999 17.0786 15.6399C16.1186 16.8399 14.5586 17.5599 12.9986 17.5599C11.4386 17.5599 9.87863 16.8399 8.91863 15.6399C8.07863 14.7999 7.59863 13.5999 7.59863 12.2799C7.59863 9.27988 9.99863 6.87988 12.9986 6.87988C15.9986 6.87988 18.2786 9.39988 18.2786 12.2799Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
          <path
            d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
        </svg>

        <span>{{ $t('layout.external.navigation.account-actions.log-in') }}</span>
      </Button>

      <Button as="NuxtLink" :to="localePath('registration')">{{
        $t('layout.external.navigation.account-actions.registration')
      }}</Button>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.actions {
  display: inline-flex;
  align-items: center;

  .login {
    display: inline-flex;
    align-items: center;

    margin-right: 0.5rem;

    svg {
      margin-right: 0.25rem;
    }
  }
}
</style>
