<script setup lang="ts">
import TemporaryNotification from '~/components/layout/external/TemporaryNotification.vue';
import DesktopMenu from '~/components/layout/external/DesktopMenu.vue';
import MobileMenu from '~/components/layout/external/MobileMenu.vue';

const headerConrainerRef = ref<HTMLDivElement | null>(null);

const layoutStore = useLayoutStore();
const { headerHeight } = storeToRefs(layoutStore);

function onTemporaryNotificationLoaded() {
  if (!headerConrainerRef.value) {
    return;
  }

  if (
    headerHeight.value !== headerConrainerRef.value.clientHeight &&
    headerConrainerRef.value.clientHeight !== 0
  ) {
    headerHeight.value = headerConrainerRef.value.clientHeight;
  }

  document.body.setAttribute(
    'style',
    `--menu-clearfix-height: ${headerConrainerRef.value!.clientHeight}px;`,
  );
}

onMounted(() => {
  window.addEventListener('resize', onTemporaryNotificationLoaded);
  window.addEventListener('scroll', onTemporaryNotificationLoaded);

  onTemporaryNotificationLoaded();
});

onUnmounted(() => {
  window.removeEventListener('resize', onTemporaryNotificationLoaded);
  window.removeEventListener('scroll', onTemporaryNotificationLoaded);
});
</script>

<template>
  <nav class="header">
    <div ref="headerConrainerRef" class="header__container">
      <ClientOnly>
        <TemporaryNotification @temporary-notification-loaded="onTemporaryNotificationLoaded" />
      </ClientOnly>

      <DesktopMenu />

      <MobileMenu />
    </div>

    <div class="header__clearfix"></div>
  </nav>
</template>

<style lang="scss" scoped>
.header {
  &__container {
    z-index: 9999;

    position: fixed;
    top: 0;

    width: 100%;

    background-color: var(--gray-bright);

    color: var(--black);

    will-change: height;

    @media (prefers-reduced-motion: no-preference) {
      transition: height 0.125s;
      will-change: height;
    }
  }

  &__clearfix {
    margin-top: var(--menu-clearfix-height, 3.75rem);
  }
}
</style>
