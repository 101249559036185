<script setup lang="ts">
import Section from '~/components/layout/external/mobile/Section.vue';

type Emits = {
  close: [];
};

const emit = defineEmits<Emits>();

const localePath = useLocalePath();

function onClose() {
  emit('close');
}
</script>

<template>
  <Section
    :title="$t('layout.links.how-does-it-work')"
    :link="localePath('how-does-it-work')"
    @close="onClose"
  />
</template>
