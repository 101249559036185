<script setup lang="ts">
import Spacer from '~/components/common/Spacer.vue';
import Logo from '~/components/common/Logo.vue';
import AccountActions from '~/components/layout/external/shared/AccountActions.vue';
import AboutUs from '~/components/layout/external/desktop/AboutUs.vue';
import EducationAndSupport from '~/components/layout/external/desktop/EducationAndSupport.vue';
import HowItWorks from '~/components/layout/external/desktop/HowItWorks.vue';
import InvestmentsOffer from '~/components/layout/external/desktop/InvestmentsOffer.vue';

const logoWidth = ref(120);
const logoMaxWidth = 120;
const logoMinWidth = 100;

function updateLogoDimensions() {
  const scrollPositionClamped = Math.min(Math.max(window.scrollY / 2, 0), 100);

  logoWidth.value = calculateIntermediateWidth(scrollPositionClamped);
}
function calculateIntermediateWidth(scrollPosition: number) {
  return logoMaxWidth - ((logoMaxWidth - logoMinWidth) / 100) * scrollPosition;
}

onMounted(async () => {
  window.addEventListener('scroll', updateLogoDimensions);

  await nextTick();

  updateLogoDimensions();
});
onUnmounted(() => {
  window.removeEventListener('scroll', updateLogoDimensions);
});
</script>

<template>
  <div class="container">
    <div class="logo">
      <NuxtLink to="/">
        <Logo
          theme="light"
          :style="{ '--custom-logo-width': `${logoWidth}px` }"
          :alt="$t('layout.external.navigation.logo-alt')"
        />
      </NuxtLink>
    </div>

    <Spacer />

    <div class="sections">
      <InvestmentsOffer />

      <HowItWorks />

      <AboutUs />

      <EducationAndSupport />
    </div>

    <Spacer />

    <AccountActions />
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;

  display: none;
  align-items: center;

  padding-block: 1.25rem;

  .logo:not(img) {
    display: inline-flex;
    align-items: center;

    width: 7.5rem;

    a {
      display: inherit;
    }
  }

  @media only screen and (min-width: 1200px) {
    display: flex;
  }
}

a {
  color: var(--gray-dark);
  text-decoration: none;

  &:visited {
    color: var(--gray-dark);
  }

  &:not(:last-of-type) {
    margin-right: 2.5rem;
  }
}

.sections {
  display: flex;
  grid-gap: 1.25rem;
}
</style>
