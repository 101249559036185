<script setup lang="ts">
import { type Column } from '~/components/layout/external/shared/types';

type Props = {
  title: string;
  link?: string;
  columns?: Column[];
  section?: string;
};

const props = withDefaults(defineProps<Props>(), {
  link: undefined,
  columns: undefined,
  section: undefined,
});

const hovering = ref(false);
const fakeActive = ref(false);
const sectionRef = ref<HTMLDivElement | null>(null);

const hasLink = computed(() => !!props.link && props.link.length > 0);
const hasExtraContent = computed(() => !!props.columns);

function onMouseEnter() {
  hovering.value = true;
}

function onMouseLeave() {
  hovering.value = false;
}

function onKeyDown(event: KeyboardEvent) {
  if ((event.key === 'Enter' || event.key === 'ArrowDown') && event.target) {
    hovering.value = true;

    event.stopPropagation();
  }
}

function onBlurCapture(event: FocusEvent) {
  if (!sectionRef.value?.contains(event.relatedTarget as Node)) {
    hovering.value = false;
  }
}

function onClickLink() {
  onMouseLeave();
}

const resetFocus = () => {
  const focusReset = document.getElementById('focus-reset') as HTMLAnchorElement | null;
  if (focusReset) {
    focusReset.focus();
  }
};
</script>

<template>
  <div
    ref="sectionRef"
    class="section"
    :class="{ 'section--hover': hovering }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @keydown="onKeyDown"
    @blur.capture="onBlurCapture"
  >
    <NuxtLink v-if="hasLink" class="section__title" :to="link" @click="resetFocus">
      <span>{{ title }}</span>
    </NuxtLink>

    <div
      v-else
      class="section__title"
      role="menuitem"
      tabindex="0"
      :class="{ 'nuxt-link-active': fakeActive }"
    >
      <span>{{ title }}</span>

      <svg
        v-if="hasExtraContent"
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="currentColor" d="M5 7L9.33013 1.76496L0.669872 1.76496L5 7Z" />
      </svg>
    </div>

    <div v-if="hasExtraContent" class="section__content-wrapper">
      <div class="section__content">
        <div v-for="column in columns" :key="column.key" class="section__column">
          <template v-if="column.links && column.links.length > 0">
            <NuxtLink
              v-for="columnLink in column.links"
              :key="columnLink.link"
              class="section__item"
              :external="columnLink.external"
              :to="columnLink.link"
              :class="{
                [`section__item--${columnLink.color}`]: true,
                'section__item--external': columnLink.external,
              }"
              :target="columnLink.external ? '_blank' : '_self'"
              @click="
                onClickLink();
                resetFocus();
              "
            >
              <div v-if="!!columnLink.icon" class="item__icon">
                <img alt=" " :src="columnLink.icon" aria-hidden="true" />
              </div>

              <div class="item__text">
                <h5 class="item__title">{{ columnLink.title }}</h5>

                <p class="item__description">{{ columnLink.description }}</p>
              </div>
            </NuxtLink>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section {
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.375rem;

    font-size: var(--font-size-base);
  }

  p {
    margin: 0;

    font-size: var(--font-size-xs);
  }

  .router-link-active,
  &:has(.router-link-active) {
    --color: var(--red-basic);

    .section__title {
      --color: var(--red-basic);
    }

    .router-link-active.section__item {
      --color: var(--red-basic);

      p {
        color: var(--color);
      }
    }
  }
}

.section--hover {
  .section__title,
  .section__title:visited {
    --color: var(--red-basic);

    svg {
      transform: rotate(-180deg);
    }
  }

  .section__content-wrapper {
    display: block;
  }
}

.section__column {
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-right: 2.5rem;
  }
}

.section__title,
.section__title:visited {
  display: flex;
  align-items: center;

  --color: var(--gray-dark);
  text-decoration: none;

  svg {
    flex-shrink: 0;

    margin-left: 0.3125rem;

    will-change: transform;
    transition: transform 0.125s;
  }

  span {
    cursor: default;
  }

  &:is(a) {
    span {
      cursor: pointer;
    }
  }

  &:is(:not(a)) {
    color: var(--color);
  }
}

.section__content-wrapper {
  position: absolute;
  top: 100%;
  right: 15px; // icon's width + margin-left

  display: none;

  padding-top: 0.3125em;
}

.section__content {
  display: flex;

  margin-top: calc(11px + (11px / 2) - 1px);
  padding: 2.5rem 3.125rem;

  background-color: var(--white);
  border-radius: 0.375rem;
  box-shadow: 0 0 1.25em rgba(0, 0, 0, 0.1);

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: calc(11px / 2 + 0.3125em);
    left: 90%;

    border: 11px solid black;
    border-color: transparent transparent white white;
    transform: rotate(135deg);
  }

  &::before {
    z-index: 1;
  }

  &::after {
    z-index: -1;

    box-shadow: 0 0 1.25em rgba(0, 0, 0, 0.1);
  }
}

.section__item {
  display: flex;

  width: 15.625rem;

  text-decoration: none;

  &:not(:last-of-type) {
    margin-bottom: 1.875rem;
  }

  &.section__item--red {
    --color: var(--red-basic);
  }

  &.section__item--black {
    --color: var(--black);
  }

  p {
    color: var(--gray-dark);
  }

  &:hover {
    &.section__item--black {
      --color: var(--red-basic);
    }

    p {
      color: var(--color);
    }
  }

  &--external {
    .item__title::after {
      content: '';

      position: absolute;
      margin-left: 0.125rem;

      width: 0.75rem;
      height: 0.75rem;

      -webkit-mask: url(https://cdn.portu.cz/gallery/images/redesign/external.svg) center
        center/contain no-repeat;
      mask: url(https://cdn.portu.cz/gallery/images/redesign/external.svg) center center/contain
        no-repeat;
      background-color: currentColor;
      overflow: hidden;
    }
  }
}

.item__title {
  position: relative;
}
</style>
