<script setup lang="ts">
type Props = {
  opened: boolean;
};
type Emits = {
  click: [opened: boolean];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

function onClick() {
  emit('click', !props.opened);
}
</script>

<template>
  <button
    type="button"
    :title="$t(`layout.external.navigation.hamburger.${opened ? 'close' : 'open'}`)"
    @click="onClick"
  >
    <svg
      width="24"
      height="24"
      fill="none"
      aria-hidden="true"
      :viewBox="!opened ? '0 0 24 24' : '0 0 19 19'"
    >
      <template v-if="!opened">
        <rect y="4" width="24" height="2" fill="black" />
        <rect y="11" width="24" height="2" fill="black" />
        <rect y="18" width="24" height="2" fill="black" />
      </template>

      <template v-else>
        <rect
          width="24.0044"
          height="1.92835"
          transform="matrix(0.70714 0.707073 -0.70714 0.707073 1.69922 0.332031)"
          fill="black"
        />
        <rect
          width="24.0044"
          height="1.92835"
          transform="matrix(-0.70714 0.707073 0.70714 0.707073 17.3047 0.332031)"
          fill="black"
        />
      </template>
    </svg>
  </button>
</template>

<style lang="scss" scoped>
button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  padding-left: 1.25rem;

  height: 100%;

  background-color: transparent;
  border-width: 0;
}
</style>
