<script setup lang="ts">
import { type Column } from '~/components/layout/external/shared/types';

type Props = {
  title: string;
  link?: string;
  columns?: Column[];
  section?: string;
};
type Emits = {
  close: [];
};

const props = withDefaults(defineProps<Props>(), {
  link: undefined,
  columns: undefined,
  section: undefined,
});
const emit = defineEmits<Emits>();

const opened = ref(false);

const hasLink = computed(() => !!props.link && props.link.length > 0);
const hasExtraContent = computed(() => !!props.columns);

function onClickLink() {
  emit('close');

  onClickHideSection();
}

function onClickTitle() {
  opened.value = true;
}

function onClickHideSection() {
  opened.value = false;

  resetFocus();
}

const resetFocus = () => {
  const focusReset = document.getElementById('focus-reset') as HTMLAnchorElement | null;
  if (focusReset) {
    focusReset.focus();
  }
};
</script>

<template>
  <div ref="sectionRef" class="section" :class="{ 'section--opened': opened }">
    <NuxtLink v-if="hasLink" class="section__title" :to="link" @click="onClickLink">
      <span>{{ title }}</span>
    </NuxtLink>

    <div v-else class="section__title" role="menuitem" @click="onClickTitle">
      <span>{{ title }}</span>

      <svg
        v-if="hasExtraContent"
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="currentColor" d="M5 7L9.33013 1.76496L0.669872 1.76496L5 7Z" />
      </svg>
    </div>

    <div v-if="hasExtraContent" class="section__content-wrapper">
      <div class="container">
        <button class="hide-section" @click="onClickHideSection">
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 1L2 8L9 15" stroke="black" stroke-width="1.5" />
          </svg>

          <span>{{ $t('global.back') }}</span>
        </button>

        <div class="section__content">
          <div v-for="column in columns" :key="column.key" class="section__column">
            <template v-if="column.links && column.links.length > 0">
              <NuxtLink
                v-for="columnLink in column.links"
                :key="columnLink.link"
                class="section__item"
                :external="columnLink.external"
                :to="columnLink.link"
                :class="{
                  [`section__item--${columnLink.color}`]: true,
                  'section__item--external': columnLink.external,
                }"
                :target="columnLink.external ? '_blank' : '_self'"
                @click="onClickLink"
              >
                <div class="item__text">
                  <h5 class="item__title">{{ columnLink.title }}</h5>
                  <p class="item__description">{{ columnLink.description }}</p>
                </div>
              </NuxtLink>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section {
  display: flex;
  align-items: center;

  h5 {
    margin: 0;
    margin-bottom: 0.3125rem;

    font-size: var(--font-size-base);
  }

  p {
    margin: 0;

    font-size: var(--font-size-xs);
  }

  .router-link-active,
  &:has(.router-link-active) {
    --color: var(--red-basic);

    .section__title {
      --color: var(--red-basic);
    }

    .router-link-active.section__item {
      --color: var(--red-basic);

      p {
        color: var(--color);
      }
    }
  }

  &--opened {
    .section__content-wrapper {
      display: block;

      background-color: var(--gray-bright);
    }
  }
}

.section__title,
.section__title:visited {
  display: flex;
  align-items: center;

  margin-bottom: 1.875rem;

  --color: var(--gray-dark);
  text-decoration: none;

  svg {
    margin-left: 0.3125rem;

    transform: rotate(-90deg);
  }

  span {
    cursor: default;
  }

  &:is(a) {
    span {
      cursor: pointer;
    }
  }

  &:is(:not(a)) {
    color: var(--color);
  }
}

.section__content-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;
}

.section__content {
  margin-bottom: 1.875rem;

  @media (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.section__column {
  @media (min-width: 576px) {
    width: calc(50% - 10px);
  }

  + .section__column {
    margin-top: 2rem;
    padding-top: 1rem;

    border-top: 0.0625rem solid var(--gray-basic);

    @media (min-width: 576px) {
      margin-top: 0;
      padding: 0;

      border-top-width: 0;
    }
  }
}

.section__item {
  display: flex;

  text-decoration: none;

  &:not(:last-of-type) {
    margin-bottom: 1.875rem;
  }

  &.section__item--red {
    --color: var(--red-basic);
  }

  &.section__item--black {
    --color: var(--black);
  }

  p {
    color: var(--gray-dark);
  }

  &:hover {
    &.section__item--black {
      --color: var(--red-basic);
    }

    p {
      color: var(--color);
    }
  }

  &--external {
    .item__title::after {
      content: '';

      position: absolute;
      margin-left: 0.125rem;

      width: 0.75rem;
      height: 0.75rem;

      -webkit-mask: url(https://cdn.portu.cz/gallery/images/redesign/external.svg) center
        center/contain no-repeat;
      mask: url(https://cdn.portu.cz/gallery/images/redesign/external.svg) center center/contain
        no-repeat;
      background-color: currentColor;
      overflow: hidden;
    }
  }
}

.hide-section {
  display: flex;
  align-items: center;

  margin-bottom: 30px;
  padding: 5px 20px 5px 0;

  background-color: transparent;
  border-width: 0;

  color: var(--black);

  svg {
    margin-right: 10px;
  }
}
</style>
