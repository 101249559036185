<script lang="ts">
type Emits = {
  temporaryNotificationLoaded: [];
};
</script>

<script setup lang="ts">
const emit = defineEmits<Emits>();

const { locale, mergeLocaleMessage } = useI18n();
watch(locale, async () => {
  await nextTick();

  emit('temporaryNotificationLoaded');
});

const layoutStore = useLayoutStore();
const { globalNotification } = storeToRefs(layoutStore);

const canShowNotification = ref(false);
const showNotification = computed(
  () =>
    canShowNotification.value &&
    !!globalNotification.value?.forLocales.includes(locale.value as 'cs' | 'en'),
);
const notificationClasses = computed(() => {
  if (!globalNotification.value) {
    return {};
  }

  return {
    [`temporary-notification--${globalNotification.value.type[locale.value as 'cs' | 'en']}`]: true,
  };
});
const notificationAttributes = computed(() => {
  const attributes = globalNotification.value?.attributes;
  if (attributes) {
    return Object.keys(attributes).map((attributeKey) => ({
      ...attributes[attributeKey],
      name: attributeKey,
    }));
  }

  return [];
});

onMounted(async () => {
  await layoutStore.getGlobalNotification();

  await nextTick();

  if (globalNotification.value) {
    globalNotification.value.forLocales.forEach((locale) => {
      mergeLocaleMessage(locale, {
        'temporary-notification': globalNotification.value!.texts[locale]!,
      });
    });

    canShowNotification.value = true;
  }

  emit('temporaryNotificationLoaded');
});
</script>

<template>
  <div
    v-if="globalNotification && showNotification"
    class="temporary-notification"
    :class="notificationClasses"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <i18n-t keypath="temporary-notification" scope="global">
            <template v-for="attribute in notificationAttributes" #[attribute.name]>
              <a
                v-if="attribute.external"
                :key="attribute.name"
                target="_blank"
                rel="external nofollow"
                :href="attribute.link"
              >
                {{ attribute.text }}
              </a>
              <NuxtLink v-if="!attribute.external" :key="attribute.name" :to="attribute.link">
                {{ attribute.text }}
              </NuxtLink>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.temporary-notification {
  background-color: var(--background-color);
  color: var(--color);

  padding: 0 1rem;

  text-align: center;

  @media (min-width: 1200px) {
    padding: 0;
  }

  &--info {
    --background-color: var(--blue-dark);
    --color: var(--white);
  }

  &--warning {
    --background-color: var(--yellow-basic);
    --color: var(--black);
  }

  &--error {
    --background-color: var(--red-neon-basic);
    --color: var(--black);
  }

  a {
    color: inherit;
  }
}

.container {
  display: flex;
  justify-content: center;

  padding: 1rem 0;

  height: auto;
}
</style>

/* test data JSON.stringify([ { product: 2, forLocales: ["cs", "sk"], texts: { cs: "Informační lišta
pro vše ostatní co není fatální, {link1}, zvýšení úroku v {link2} apod", sk: "Toto bude lišta pro SK
s linkem3 {link3}.", }, type: { cs: "info", sk: "warning", }, attributes: { link1: { text:
"plánované webináře", link: "https://google.com", external: true, }, link2: { text: "Investiční
rezervě", link: "/faq/investicni-rezerva", external: false, }, link3: { text: "test", link:
"/ako-investujeme", external: false, }, }, }, ]); */
