<script setup lang="ts">
import { type Column } from '~/components/layout/external/shared/types';
import Section from '~/components/layout/external/desktop/Section.vue';

const { t } = useI18n();
const localePath = useLocalePath();

const columns = computed<Column[]>(() => [
  {
    key: 'column-1',
    links: [
      {
        link: localePath('faq'),
        title: t('layout.external.navigation.education-and-support.faq.title'),
        description: t('layout.external.navigation.education-and-support.faq.description'),
        color: 'black',
      },
      {
        link: localePath('contact'),
        title: t('layout.external.navigation.education-and-support.support.title'),
        description: t('layout.external.navigation.education-and-support.support.description'),
        color: 'black',
      },
      {
        link: localePath('element'),
        title: t('layout.external.navigation.education-and-support.element.title'),
        description: t('layout.external.navigation.education-and-support.element.description'),
        color: 'black',
      },
    ],
  },
  {
    key: 'column-2',
    links: [
      {
        link: 'https://magazin.portu.cz/investovani-od-a-do-z/sberatelske-predmety/',
        title: t('layout.external.navigation.education-and-support.blog.title'),
        description: t('layout.external.navigation.education-and-support.blog.description'),
        color: 'red',
        external: true,
      },
      {
        link: 'https://rozumiminvesticim.cz',
        title: t(
          'layout.external.navigation.education-and-support.investment-literacy-index.title',
        ),
        description: t(
          'layout.external.navigation.education-and-support.investment-literacy-index.description',
        ),
        external: true,
        color: 'black',
      },
    ],
  },
]);
</script>

<template>
  <Section
    key="desktop-education-and-support"
    :title="$t('layout.external.navigation.education-and-support.title')"
    :columns="columns"
  />
</template>
